/**
  Icons
 */

.rus {
  background: url("assets/images/flags/ru.svg") no-repeat;
}

.kaz {
  background: url("assets/images/flags/kz.svg") no-repeat;
}

.eng {
  background: url("assets/images/flags/us.svg") no-repeat;
}

.arrow-down {
  background: url("assets/images/icons/down.svg") no-repeat;
}

.filter-down {
  margin-left: 18px;
  height: 7px;
  width: 14px;
  display: inline-block;
  background: url("assets/images/icons/filter-down.svg") no-repeat;
}

.pickup {
  background: url("assets/images/icons/pickup.svg") no-repeat;
}

.cash {
  background: url("assets/images/icons/cash.svg") no-repeat;
}

.view01 {
   background: url("assets/images/view/view02.svg") center no-repeat;
}
.view02 {
  background: url("assets/images/view/view03.svg") center no-repeat;
}
.view03 {
  background: url("assets/images/view/view01.svg") center no-repeat;
}

.icon-product {
  width: 22px;
  height: 22px;
  display: inline-block;
  @include media(640px) {
    width: 32px;
    height: 32px;
  }
}

.icon-menu {
  width: 24px;
  height: 14px;
  display: inline-block;
}

.icon-order {
  width: 30px;
  height: 24px;
  margin-right: 16px;
  display: inline-block;
}

.icons {
  width: 24px;
  height: 24px;
  display: inline-block;

  &.question {
    width: 16px;
    height: 16px;
    background: #E5E5E5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.icons-small {
  width: 12px;
  height: 12px;
  display: inline-block;
}