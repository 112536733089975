.site-footer {
  padding-top: 32px;
  background-color: $color-blue-h;
  color: #fff;

  a {
    color: #fff;
  }
  .copyright {
    font-size: 12px;
    text-align: center;
    margin-bottom: 0;
    color: $color-grey;
    @include media(640px) {
      font-size: inherit;
    }
  }
}

.footer-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;
  @include media(640px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.footer-top {
  display: grid;
  margin-bottom: 30px;
  grid-template-areas: "about" "help" "news" "socials";
  justify-content: center;
  grid-row-gap: 24px;
  @include media(640px) {
    justify-content: flex-start;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "about help socials" "news news news";
  }
  @include media(990px) {
    grid-row-gap: inherit;
    grid-template-columns: repeat(2, 1fr) 345px 1fr;
    grid-template-areas: "about help news socials";
  }
  @include media(1408px) {
    grid-template-columns: repeat(2, 1fr) 448px 1fr;
  }

  .input {
    border: 1px solid #FFFFFF;
    color: #fff;
    background-color: transparent;

    &::placeholder {
      color: #fff;
    }
  }
}

.foot01 {
  grid-area: about;
}
.foot02 {
  grid-area: help;
}
.foot03 {
  grid-area: news;
}
.foot04 {
  grid-area: socials;
}

.footer-bottom {
  padding: 16px 0;
  border-top: 1px solid #003976;
}

.social-links {
  justify-content: space-between;

  a {
    display: inline-block;

    svg {
      fill: #ffffff;
    }
    &:hover, &:focus {
      svg {
        fill: rgba(#fff, .8);
      }
    }
  }
}

.footer-menu {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
  li+li {
    margin-top: 9px;
  }
  a {
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}