.search-wrap {
  position: relative;

  .search {
    color: #8E8D90;
    border-radius: 80px;
    background-color: $color-grey;

    &::placeholder {
      color: #8E8D90;
    }
  }
  .btn {
    position: absolute;
    top: 12px;
    right: 16px;
    height: 24px;
    cursor: pointer;

    svg {
      fill: #8E8D90
    }
    &:hover, &:focus {
      svg {
        fill: $color-blue;
      }
    }
  }
}

.header-top {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid $color-shadow;
  @include media(480px) {
    flex-direction: row;
    max-height: 68px;
  }
  @include media(640px) {
    justify-content: inherit;
    max-height: 88px;
  }
  @include media(990px) {
    justify-content: space-between;
  }

  .btn-menu {
    position: absolute;
    right: 8px;
    margin-right: 0;
    @include media(480px) {
      display: none;
    }
  }
  .search-wrap {
    display: none;
    @include media(990px) {
      display: block;
      margin-left: 24px;
      width: 340px;
    }
    @include media(1200px) {
      width: 390px;
    }
    @include media(1408px) {
      margin-left: 56px;
      width: 408px;
    }
  }
  .circle-grey {
    position: relative;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    background-color: $color-grey;
    @include media(640px) {
      width: 56px;
      height: 56px;
    }

    .count {
      position: absolute;
      border-radius: inherit;
      top: -9px;
      right: 5px;
      width: 16px;
      height: 16px;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #fff;
      background-color: $color-red;
    }
    svg {
      width: 18px;
      @include media(640px) {
        width: 100%;
      }
    }
  }
  .wishlist {
    margin-right: 16px;
    @include media(640px) {
      margin-right: 24px;
    }
    svg {
      fill: $color-blue;
    }
  }
  .card {
    cursor: pointer;
    margin-right: 9px;
    @include media(640px) {
      margin-right: 24px;
    }

    .subtitle {
      margin-bottom: 0;
    }
    .icon {
      stroke: $color-blue;
    }
    &:hover, &:focus {
      .tooltip-wrap {
        opacity: 1;
        visibility: visible;
      }
    }
    .tooltip-wrap {
      width: 300px;
      right: -225%;
      @include media(640px) {
        width: 416px;
        right: -55px;
      }

      .pointer {
        right: 35%;
        @include media(640px) {
          right: 15%;
        }
      }
    }
  }
}

.card-list {
  margin-bottom: 24px;
}

.card-btn {
  padding: 0 16px;

  a {
    display: inline-flex;
    align-items: center;
  }
}

.card-img {
  width: 90px;
  margin-right: 16px;
  @include media(640px) {
    width: 104px;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
}

.card-modal {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 1;
  visibility: visible;
  z-index: 99;
  transition: all .25s ease-out;
  @include media(640px) {
    width: 380px;
    top: 5px;
    right: 5px;
  }
  @include media(1200px) {
    width: 38vw;
  }
  @include media(1621px) {
    width: 30.5vw;
  }

  &.active {
    opacity: 0;
    visibility: hidden;
  }
  .tooltip {
    padding-top: 40px;
    padding-bottom: 16px;
  }
  .card-list {
    margin-bottom: 16px;
  }
  .card-item {
    padding: 24px;

    small {
      font-size: inherit;
      line-height: inherit;
    }
  }
  .card-img {
    width: 100%;
    @include media(640px) {
      width: 116px;
    }
  }
  .card_title {
    font-size: 18px;
    line-height: 24px;
    padding-left: 24px;
    margin-bottom: 0;

    @include media(1408px) {
      font-size: 24px;
      line-height: 29px;
    }
  }
  .card-btn {
    padding: 0 24px;
    @include media(1200px) {
      display: flex;
      justify-content: space-between;
    }
  }
  .card-body {
    p {
      font-size: 16px;
      line-height: 24px;
      @include media(1408px) {
        font-size: 18px;
      }
    }
  }
}

.card-continue {
  color: $color-blue;
  font-weight: bold;
  margin-bottom: 10px;
  @include media(1200px) {
    margin-bottom: 0;
  }

  &:hover, &:focus {
    color: $color-gray
  }
}

.card-body {
  flex: 1;
  margin: auto;

  p {
    font-size: 13px;
    @include media(640px) {
      font-size: inherit;
    }

    &:first-of-type {
      margin-bottom: 16px;
    }
  }
  .ds-menu-center {
    flex-direction: column;
    @include media(640px) {
      flex-direction: row;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.card-item {
  border-bottom: 1px solid #E5E5E5;
  padding: 16px;

  small {
    display: block;
    margin-bottom: 6px;
  }
}

.header-left, .header-right {
  align-items: center;
}

.header-left {
  .dropdown-lang {
    padding-right: 0;
    height: auto;
    margin-left: 24px;
    @include media(640px) {
      display: none;
    }
  }
}

.header-right {
  margin-top: 8px;
  padding-bottom: 10px;
  justify-content: space-between;
  @include media(480px) {
    padding-bottom: 0;
    margin-top: 0;
  }
  @include media(640px) {
    flex: 1;
    justify-content: flex-end;
  }
  @include media(990px) {
    justify-content: inherit;
    flex: inherit;
  }

  .dropdown-lang {
    display: none;
    @include media(640px) {
      display: flex;
    }
    @include media(1200px) {
      display: none;
    }
  }
}

.site-nav {
  display: none;
  @include media(990px) {
    display: flex;
    color: $color-gray;

    .site-menu {
      display: flex;
      place-content: center;
      margin: 0 auto;
      padding: 0;
      position: relative;
    }
    .site-menu-item {
      list-style: none;

      %menu_item-link {
        display: block;
        padding: 23px 12px 24px;
        @include media(1200px) {
          padding: 23px 16px 24px;
        }
        @include media(1408px) {
          padding: 23px 24px 24px;
        }
        @include media(1621px) {
          padding: 23px 32px 24px;
        }
      }
      .link {
        @extend %menu_item-link;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
      }
      .no-link {
        @extend %menu_item-link
      }
      &:last-child {
        a {
          color: $color-red;
          &:after {
            content: ' %';
          }
        }
      }
      &:hover, &:focus {
        .link {
          border-left: 1px solid #E5E5E5;
          border-right: 1px solid #E5E5E5;
        }
        .site-menu-dropdown {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.site-menu-dropdown {
  @include media(990px) {
    margin-right: -24px;
    padding: 23px 23px 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 25;
    left: 0;
    right: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #E5E5E5;

    li {
      list-style: none;
    }
  }
  @include media(1200px) {
    margin-right: -32px;
  }
  @include media(1408px) {
    margin-right: -48px;
  }
  @include media(1621px) {
    margin-right: -56px;
  }
}

.catalog-link {
  @include media(990px) {
    display: block;
    color: $color-blue;
    line-height: 24px;
    text-transform: uppercase;
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid #E5E5E5;

    &.no-child {
      margin-bottom: 0;
    }
  }
}

.site-menu-cats {
  @include media(990px) {
    float: left;
    width: calc(33.333333% - 24px);
    margin-bottom: 24px;
    margin-right: 24px;
  }
  @include media(1200px) {
    margin-right: 24px;
    width: calc(25% - 24px);
  }
  @include media(1408px) {
    margin-right: 48px;
    width: calc(25% - 48px);
  }
  @include media(1621px) {
    margin-right: 56px;
    width: calc(25% - 56px);
  }
}

.site-menu-child {
  @include media(990px) {
    padding-left: 0;

    li {
      color: #9C9C9F;

      & + li {
        margin-top: 16px;
      }
    }
  }
}

.lang-link {
  align-items: center;
  color: $color-blue;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;

  &:hover, &:focus {
    text-decoration: underline;
  }
  .icons-small {
    margin-right: 4px;
  }
  .arrow-down {
    margin-right: 0;
    margin-left: 8px;
    margin-top: 5px;
  }
}

.languages-list {
  display: none;
  padding: 0;
  margin: 0 25px 0 0;
  @include media(1200px) {
    display: flex;
  }

  li {
    list-style: none;
    margin-right: 8px;
    border-right: 1px solid $color-blue;
    padding-right: 8px;
    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }
    &.active {
      .language-select {
        text-decoration: underline;
      }
    }
  }
}

.language-select {
  height: auto;
  cursor: pointer;
}

.delivery-link-wrap {
  padding: 10px 0;
  height: 100%;
  margin-right: 16px;
  @include media(640px) {
    padding: 20px 0;
    margin-right: 24px;
  }
}

.delivery-link {
  padding: 0 16px;
  border-right: 1px solid $color-gray;
  border-left: 1px solid $color-gray;
  height: 100%;
  @include media(640px) {
    padding: 0 24px;
  }

  .icons {
    display: block;
    @include media(640px) {
      display: none;
      margin-right: 10px;
    }
    @include media(1200px) {
      display: block;
    }
  }
  span {
    display: none;
    @include media(640px) {
      display: block;
    }
  }
  svg {
    stroke: #999999;
  }
  &:hover, &:focus {
    svg {
      stroke: $color-blue
    }
  }
}

.profile-logged {
  &:hover, &:focus {
    border-right: 1px solid #E5E5E5;
    border-left: 1px solid #E5E5E5;
    .profile-tooltip {
      opacity: 1;
      display: block;
    }
  }
}

.profile {
  height: 100%;
  padding-right: 5px;
  padding-left: 9px;
  cursor: pointer;
  position: relative;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;

  .icons-small {
    align-self: center;
    margin-left: 8px;
  }
}

.profile-tooltip {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  z-index: 5;
  opacity: 0;
  width: 212px;
  transition: opacity .3s;
  background-color: #fff;
  border: 1px solid #E5E5E5;

  ul {
    margin: 0;
    padding: 24px 0;

    li {
      list-style: none;
      padding-left: 16px;

      svg {
        fill: #9C9C9F;
      }
      &:hover, &:focus {
        svg {
          fill: $color-blue;
        }
      }
      .icons {
        margin-right: 16px;
      }
    }
    li+li {
      margin-top: 16px;
    }
    a {
      display: flex;
      align-items: center;
    }
  }
  .bottom {
    padding: 16px 0;
    border-top: 1px solid #E5E5E5;

    a {
      font-weight: 600;
      color: $color-blue;
      padding-left: 16px;
    }
  }
}

.dropdown-lang {
  align-items: center;
  padding-right: 16px;
  height: 70%;
  @include media(1200px) {
    display: none;
  }
}

.profile-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  @include media(640px) {
    width: 56px;
    height: 56px;
  }
}

.btn-menu {
  padding-left: 8px;
  width: 24px;
  height: 20px;
  margin-right: 48px;
  cursor: pointer;
  @include media(640px) {
    display: none;
  }
  &.checked {
    span {
      transform: rotate(45deg);

      &:before, &:after {
        top: 0;
      }
      &:before {
        transform: rotate(0);
      }
      &:after {
        transform: rotate(90deg);
      }
    }
  }
  span, span:after, span:before {
    display: block;
    position: absolute;
    width: inherit;
    height: 1px;
    background-color: $color-blue-h;
    transition-duration: .3s;
  }
  span {
    &:before {
      content: '';
      top: -6px;
    }
    &:after {
      content: '';
      top: 6px;
    }
  }
}

.hamburger-menu {
  display: none;
  @include media(640px) {
    display: flex;
    position: relative;
    align-items: center;
    padding-right: 16px;
    margin-right: 48px;
    font-weight: 500;
    cursor: pointer;
    color: $color-blue;

    .icon-menu {
      margin-right: 16px;

      svg {
        fill: $color-blue
      }
    }
    &:hover, &:focus {
      color: #021D3A;

      .icon-menu {
        svg {
          fill: #021D3A;
        }
      }
      .dropdown-catalog-list {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.header-bottom {
  display: flex;
  align-items: center;
  padding-top: 12px;
  @include media(640px) {
    align-items: inherit;
  }
  @include media(990px) {
    display: none;
  }

  .search-wrap {
    width: 100%;
  }
  .btn-menu {
    display: none;
    @include media(480px) {
      display: block;
    }
    @include media(640px) {
      display: none;
    }
  }
}

.dropdown-catalog-list {
  position: absolute;
  left: 0;
  z-index: 15;
  top: 100%;
  padding: 7px 0;
  width: 220px;
  background: #fff;
  box-shadow: 0 0 14px rgba(18, 12, 37, .25);
  text-align-last: left;
  visibility: hidden;
  opacity: 0;
  margin: 0;
  transition: opacity .3s;
  @include media(768px) {
    width: 300px;
  }

  li {
    list-style: none;
  }
  a {
    color: $color-blue;
  }
}

.dropdown-catalog-item {
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 7px 15px;
  transition: all .3s;

  &:hover, &:focus {
    background-color: #021D3A;

    .dropdown-cat {
      opacity: 1;
    }
    & > a {
      color: #fff
    }
  }
}

.dropdown-cat-item {
  width: calc(50% - 20px);
  margin-right: 24px;
  margin-bottom: 10px;

  a {
    display: block;
    padding: 3px 15px;
    font-size: 12px;

    &:hover, &:focus {
      color: #021D3A;
      background-color: rgba(#021D3A, .15);
    }
    &.parent {
      font-weight: 600;
    }
  }
}

.dropdown-child-list {
  padding-left: 20px;
  margin-bottom: 10px;
}

.dropdown-cat-list {
  padding: 7px 0;
  background: #fff;
  box-shadow: 0 0 14px rgba(18, 12, 37, .25);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 644px;
  overflow: auto;
}

.dropdown-cat {
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  padding-left: 10px;
  width: 60vw;
  background: 0 0;
  pointer-events: none;
  opacity: 0;
  transition: all .3s;
  @include media(768px) {
    width: 55vw;
  }
}

.mobile-menu-wrap {
  width: 100%;
  height: 100vh;
  background: $color-blue;
  position: fixed;
  top: 0;
  z-index: 999;
  display: none;
  overflow-y: auto;
}

.mobile-menu-header {
  background: #003976;
  padding: 20px 0;
}

.mobile-menu-close {
  display: block;
  top: 32px;
  border: 0;
  padding: 10px;
  background: 0 0;
  position: absolute;
  left: 15px;
  cursor: pointer;

  &:hover, &:focus {
    path {
      stroke: $color-blue
    }
  }
  path {
    stroke: white;
  }
}

.header-logo {
  display: block;
  max-height: 65px;
  width: 100%;
  text-align: center;

  img {
    max-height: inherit;
  }
}

.mobile-menu-item,
.mobile-menu-sublist li {
  position: relative;
}

.mobile-menu-link {
  color: #fff;
  padding: 15px 65px 15px 15px;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid rgba(199, 203, 205, .3);
}

.mobile-menu-arrow {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  min-height: 53px;
  border: 0;
  background: 0 0;
  color: #fff;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    svg {
      transform: rotate(90deg);
    }
  }
  svg {
    transform: rotate(270deg);
    transition: transform .3s;
  }
  path {
    stroke: #fff;
    transition: stroke .3s;
  }
}

.mobile-menu-sublist {
  display: none;
  width: 100%;
  padding-left: 0;

  li {
    &:before {
      content: "";
      position: absolute;
      top: 17px;
      left: 15px;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #fff;
    }
  }
  a {
    display: block;
    padding: 10px 40px;
    color: #fff;
    border-bottom: 1px solid rgba(199, 203, 205, .3);
  }
}