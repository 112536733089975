.actual-card {
  margin-bottom: 24px;
  @include media(640px) {
    margin-bottom: 32px;
  }
  @include media(1200px) {
    min-height: 450px;
  }
  @include media(1408px) {
    min-height: 496px;
  }

  p {
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 0;
    color: $color-gray;
    @include media(640px) {
      font-size: inherit;
    }
  }
}

.actual-mount {
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  z-index: 5;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 3px;
  margin: -48px auto 0;
  width: calc(100% - 48px);
  @include media(640px) {
    padding-bottom: 32px;
  }
}

.actual-wrap {
  padding-top: 24px;
  @include media(640px) {
    padding-top: 32px;
  }
}