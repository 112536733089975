.search_mr {
  margin-bottom: 16px;
}

.search-top {
  flex-wrap: wrap;
  padding-top: 32px;
}

.search-item {
  margin-right: 24px;
  margin-bottom: 24px;
  max-width: calc(33.333333% - 16px);
  @include media(640px) {
    margin-right: 40px;
  }

  &:nth-of-type(3n+3) {
    margin-right: 0;
    @include media(640px) {
      margin-right: 40px;
    }
  }
  &:last-of-type {
    margin-right: 0;
  }
  .search-img {
    display: flex;
    margin-bottom: 16px;
    width: auto;
    height: auto;
    object-fit: cover;
    border: 1px solid #E5E5E5;
    border-radius: 100%;
    @include media(640px) {
      width: 152px;
      height: 152px;
    }

    img {
      margin: auto;
      border-radius: inherit;
    }
  }
  .subtitle {
    text-align: center;
  }
}