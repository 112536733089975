.basket_content {

  .section-title {
    color: #101011;
    margin-bottom: 24px;
    @include media(640px) {
      margin-bottom: 32px;
    }
  }
  h5 {
    font-weight: 500;
    margin-bottom: 16px;
  }
  .btn-blue {
    display: flex;
  }
}

.btn-wrap {
  display: flex;
  justify-content: flex-end;
}

.promo {
  margin-bottom: 32px;

  .input {
    color: #9C9C9F;
  }
  p {
    color: #8E8D90;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
}

.promo-input {
  margin-bottom: 8px;
  max-width: 568px;
  flex-direction: column;
  @include media(640px) {
    flex-direction: row;
  }

  .btn {
    align-self: flex-end;
    margin-top: 12px;
    @include media(640px) {
      margin-top: 0;
      align-self: inherit;
    }
  }
}

.promo-btn {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  min-width: 158px;
  width: 158px;
  letter-spacing: 0.01em;
  color: $color-blue;
  border: 2px solid $color-blue;
  box-sizing: border-box;
  border-radius: 3px;
  transition: background-color .3s;

  &:hover, &:focus {
    background-color: $color-blue;
    color: #fff;
  }
}

.order-item {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  border-bottom: 1px solid #E5E5E5;
  @include media(640px) {
    flex-direction: row;
    padding: 24px 0;
    align-items: center;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    @include media(768px) {
      position: static;
    }

    svg {
      fill: #C8C8C8;

      &:hover, &:focus {
        fill: #8E8D90;
      }
    }
  }
}

.order-children {
  flex: 1;
}

.order-menu {
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;

  li {
    width: calc(50% - 8px);
    list-style: none;
    margin-right: 16px;
    margin-bottom: 24px;
    @include media(640px) {
      width: auto;
      margin-bottom: 0;
      margin-right: 18px;
    }
    @include media(768px) {
      margin-right: 0;
    }

    &:nth-child(2n+2) {
      margin-right: 0;
      @include media(640px) {
        margin-right: 24px;
      }
      @include media(768px) {
        margin-right: 0;
      }
    }
  }
  .hero-link {
    position: absolute;
    right: 0;
    bottom: 0;
    @include media(640px) {
      position: static;
    }
  }
}

.count-wrap {
  display: flex;
  position: relative;
  max-width: 152px;

  .count-up {
    width: 24px;
    height: 24px;
    background-color: $color-blue;
    border-radius: 2px;
    position: absolute;
    cursor: pointer;
    top: 12px;

    &:hover, &:focus {
      background-color: $color-blue-h;
    }
  }
  .input {
    padding-left: 0;
    text-align: center;
  }
  .minus {
    left: 8px;
  }
  .plus {
    right: 8px;
  }
}

.order-title {
  font-weight: 600;
  font-size: 11px;
  line-height: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  @include media(640px) {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 32px;
  }

  & ~   p {
    color: #8E8D90;
    margin-bottom: 0;
  }
}

.order-img {
  width: 100%;
  height: 185px;
  margin-bottom: 16px;
  position: relative;
  @include media(640px) {
    display: none;
  }
  @include media(990px) {
    display: block;
    width: 166px;
    height: 172px;
    margin-right: 24px;
    margin-bottom: 0;
  }
  @include media(1200px) {
    width: 184px;
    height: 192px;
    margin-right: 48px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.order-details {
  margin-bottom: 0;
  padding: 0;
  font-weight: 500;
  margin-top: 32px;

  li {
    list-style: none;
  }
  li+li {
    margin-top: 16px;
  }
}

.present {
  padding-top: 16px;
  margin-bottom: 16px;
  align-items: center;
  position: relative;

  .icons {
    margin-left: 8px;
    &.question {
      svg {
        fill: $color-black;
        stroke: transparent;
      }
    }
    svg {
      fill: $color-blue;
      stroke: $color-blue
    }
    &:hover, &:focus {
      svg {
        fill: $color-blue-h;
        stroke: $color-blue-h
      }
    }
  }
}

.questions-icon {
  &:hover, &:focus {
    .tooltip-wrap {
      opacity: 1;
      visibility: visible;
    }
  }
  .tooltip-wrap {
    padding-top: 0;
    width: 90vw;
    top: 140%;
    left: 0;
    @include media(640px) {
      width: 60vw;
      top: -24px;
      left: 187px;
    }
    @include media(768px) {
      width: 506px;
    }

    .pointer {
      top: -15px;
      left: 30%;
      @include media(640px) {
        top: 32px;
        left: -18px;
        transform: rotate(270deg);
      }
    }
  }
  .tooltip {
    padding: 16px 24px;
    color: #9C9C9F;
  }
}