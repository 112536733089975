.product-view-wrap {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  border-bottom: 1px solid #E5E5E5;
  @include media(640px) {
    padding-bottom: 32px;
  }
  @include media(1408px) {
    flex-direction: row;
  }
}

.product-slide-wrap {
  position: relative;
  overflow: hidden;
  @include media(1408px) {
    order: inherit;
    padding-right: 48px;
    width: calc(100% - 328px);
  }
}

.product-continue {
  top: 0;
  bottom: 0;
  width: 63px;
  height: 100%;
  position: absolute;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0) 100%);

  &.next {
    right: -1px;
    @include media(1408px) {
      right: 46px;
    }
  }
  &.prev {
    left: -1px;
    transform: rotate(180deg);
  }
}

.product-slide {

  .slick-slide {
    padding-right: 8px;
    padding-left: 8px;

    img {
      height: 240px;
      object-fit: contain;
      @include media(640px) {
        height: 365px;
      }
      @include media(768px) {
        height: calc(100vh - 272px);
      }
      @include media(1408px) {
        height: 792px;
      }
    }
  }
}

.product-view-content {
  padding-top: 32px;
  @include media(990px) {
    display: flex;
  }
  @include media(1408px) {
    width: 328px;
    display: block;
    padding-top: 0;
  }
}

.product-views {
  display: flex;
  flex-direction: column;
  @include media(640px) {
    flex-direction: row;
  }
  @include media(1408px) {
    display: block;
  }
}

.product-view-header {
  margin-right: 24px;
  @include media(1408px) {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 24px;
    margin-right: 0;
  }

  .ratings-wrap {
    margin-top: 24px;
  }
}

.product-title {
  font-size: 18px;
  line-height: normal;
  color: #101011;
  font-weight: normal;
  margin-bottom: 16px;
  @include media(640px) {
    font-size: 20px;
    line-height: 32px;
  }
}

.ratings-wrap {
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    margin-left: 12px;
    line-height: 16px;
    color: #9C9C9F;
  }
}

.ratings {
  display: flex;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
  li+li {
    margin-left: 5px;
  }
}

.product-color-nav {

  ul {
    flex-wrap: wrap;
    margin: 0 -12px -16px;
    padding: 0;

    li {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      padding: 0 12px;
      list-style: none;
      margin-bottom: 16px;
      @include media(1408px) {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
  img {
    width: 64px;
    height: 64px;
    cursor: pointer;
    object-fit: cover;
    border: 3px solid transparent;
    transition: border-color .3s;

    &:hover, &:focus {
      border: 3px solid #8E8D90;
    }
  }
  small {
    display: inline-block;
    margin-top: 8px;
    font-weight: 500;
  }
}

.product-view-middle {
  @include media(640px) {
    min-width: 320px;
  }
  @include media(1408px) {
    padding-bottom: 40px;
  }
}

.product-subtitle {
  font-size: 12px;
  margin-bottom: 8px;
  padding-top: 16px;
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  color: $color-black;
  @include media(640px) {
    font-size: inherit;
    padding-top: 24px;
  }

  &:first-of-type {
    padding-top: 16px;
    @include media(640px) {
      padding-top: 48px;
    }
    @include media(1408px) {
      padding-top: 24px;
    }
  }
}

.product-view-footer {
  @include media(990px) {
    padding-left: 24px;
  }
  @include media(1408px) {
    padding-left: 0;
    border-top: 1px solid #e5e5e5;
  }
}

.product-blocks {
  border-top: 1px solid #e5e5e5;
  padding-top: 16px;
  margin-bottom: 16px;
  @include media(640px) {
    padding-top: 24px;
    margin-bottom: 24px;
  }
  @include media(990px) {
    padding-top: 32px;
    margin-bottom: 32px;
  }

  .title {
    margin-bottom: 24px;
  }
}

.product-code {
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 8px;

  & ~ p {
    color: #8E8D90;
    font-size: 12px;
    @include media(640px) {
      font-size: inherit;
    }
  }
}

.product-delivery {
  flex-wrap: wrap;
  @include media(990px) {
    flex-wrap: inherit;
  }
}

.product-view-row {
  flex-direction: column;
  @include media(640px) {
    flex-direction: inherit;
  }
}

.product-view-detail {
  min-width: 240px;
  margin-bottom: 16px;
  @include media(990px) {
    margin-bottom: 0;
  }

  ul {
    padding-left: 18px;

    li+li {
      margin-top: 6px;
    }
  }
}

.leave-wrap {
  display: flex;
  margin-bottom: 16px;
  @include media(768px) {
    align-items: center;
  }
}

.leave-img {
  min-width: 45px;
  width: 45px;
  height: 45px;
  margin-right: 16px;
  background-color: $color-blue;
  @include media(640px) {
    min-width: 72px;
    width: 72px;
    height: 72px;
  }

  &.care {
    background-color: transparent;
  }
}

.featured-product-wrap {
  position: relative;
  overflow: hidden;

  .next {
    right: -1px;
  }
  .product-item_img,
  .product-continue {
    height: 220px;
    @include media(640px) {
      height: 288px;
    }
  }
}

.featured-slide {

  .slick-track {
    margin: 0;
  }
  .slick-slide {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.reviews-wrap {
  padding-top: 4px;

  .hero-link {
    float: none;
  }
}

.reviews-header {
  flex-direction: column;
  margin-bottom: 24px;
  @include media(640px) {
    align-items: center;
    flex-direction: row;
  }

  .code {
    margin-left: 16px;
  }
  .ratings-wrap {
    justify-content: flex-end;
    @include media(640px) {
      margin-left: 8px;
      justify-content: inherit;
    }
  }
  p {
    margin-bottom: 0;
  }
}

.reviews-list {
  max-height: 430px;
  overflow-y: hidden;
  position: relative;
  margin-bottom: 8px;
  transition: max-height .3s;

  &.show {
    max-height: 100%;
  }
}

.reviews-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}

.reviews-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  @include media(768px) {
    flex-direction: row;
  }

  &:last-child {
    margin-bottom: 0;
  }
  .product-code {
    color: #101011;
  }
}

.reviews-left {
  @include media(768px) {
    margin-right: 24px;
  }

  .ratings-wrap {
    margin-bottom: 8px;
  }
}

.reviews-right {
  flex: 0 0 75%;
}

.wrap-drop {
  cursor:pointer;
  padding: 16px;
  position:relative;
  width: 100%;
  z-index:3;
  border: 1px solid #E5E5E5;
  border-radius: 4px;

  span {
    color: $color-gray;
  }
  &::after {
    background: url(assets/images/icons/filter-down.svg) 95% no-repeat;
    content: "";
    display: inline-block;
    width: 14px;
    height: 18px;
    float: right;
  }
  &.active {
    .drop {
      display:block;
      opacity:1;
    }
  }
  .drop {
    background-color: white;
    border: 1px solid #E5E5E5;
    border-radius: 0 0 3px 3px;
    display:none;
    padding: 16px 24px;
    left:0;
    list-style:none;
    margin: 0;
    opacity:0;
    max-height: 120px;
    overflow-y: scroll;
    position:absolute;
    right:0;
    top:100%;
    z-index:2;

    &::-webkit-scrollbar
    {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb
    {
      border-radius: 1px;
      background-color: #E5E5E5;
      height: 64px;
    }
    li {
      display: flex;
      justify-content: space-between;

      &:hover, &:focus {
        color: $color-blue
      }
      & + li {
        margin-top: 16px;
      }
      &.no-product {
        color: $color-gray;

        a {
          color: $color-blue;
        }
      }
      .last {
        color: #E8484C;
      }
    }
  }
}

.product-size {
  .present {
    padding-top: 8px;
    margin-bottom: 24px;
  }
  .heart {
    position: static;
    width: 48px;
    min-width: 48px;
    height: inherit;

    &.active {
      background-color: $color-blue;
      border-color: transparent;

      svg {
        fill: white;
      }
    }
    &:hover, &:focus {
      background-color: $color-blue;
      border-color: transparent;

      svg {
        fill: white;
      }
    }
    svg {
      fill: $color-blue;
    }
  }
  .heart, .count-wrap {
    margin-right: 16px;
  }
  .btn-blue {
    padding: 0 30px;
  }
  .questions-icon {
    .tooltip-wrap {
      width: auto;
      top: -34px;
      left: 208px;
      min-width: 145px;
    }
    .tooltip {
      font-size: 12px;
      line-height: 16px;
    }
  }
}