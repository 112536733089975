.order-wrap {
  display: flex;
  flex-direction: column;
  @include media(990px) {
    flex-direction: row;
  }
}

.order-sidebar {
  width: 100%;
  margin-bottom: 24px;
  @include media(990px) {
    width: 184px;
    margin-bottom: 0;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    @include media(640px) {
      flex-wrap: inherit;
    }
    @include media(990px) {
      display: block;
    }

    li {
      list-style: none;
      border-bottom: 1px solid #E5E5E5;
      margin-bottom: 16px;
      padding-right: 8px;
      padding-left: 8px;
      padding-bottom: 16px;
      transition: border-bottom-color .3s;
      @include media(640px) {
        padding-right: 16px;
        padding-left: 16px;
        margin-bottom: 0;
      }
      @include media(990px) {
        padding-right: 0;
        padding-left: 8px;
      }

      &.active {
        border-bottom-color: $color-blue;

        a {
          color: $color-blue;
          font-weight: 500;
        }
      }
    }
    li+li {
      @include media(990px) {
        margin-top: 16px;
      }
    }
    a {
      &:hover, &:focus {
        font-weight: 500;
      }
    }
  }
}

.order-body {
  @include media(990px) {
    width: calc(100% - 184px);
    padding-left: 56px;
  }

  .section-title {
    &.order-number {
      font-size: 20px;
      line-height: normal;
      margin-bottom: 16px;
      @include media(640px) {
        font-size: 26px;
        line-height: 34px;
      }
      @include media(990px) {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
}

.order-width {
  @include media(768px) {
    max-width: 568px;
  }
}

.order-decor-radio {
  display: flex;
  align-items: center;

  .radio-wrap {
    margin-right: 32px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.order-form {
  padding-top: 24px;
  @include media(640px) {
    padding-top: 32px;
  }

  .btn-group {
    margin-top: 24px;
    @include media(640px) {
      margin-top: 32px;
    }
  }
  .radio-wrap {
    margin-bottom: 24px;
  }
}

.order-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  margin-top: 24px;
  @include media(640px) {
    margin-top: 32px;
  }
}

.order-filter {

  .select {
    padding-left: 16px;
    padding-right: 40px;
    margin-right: 24px;
    width: auto;
  }
}

.my-order-img {
  width: 100%;
  height: 190px;
  margin-bottom: 16px;
  margin-right: 24px;
  position: relative;
  flex-wrap: wrap;
  display: flex;
  @include media(640px) {
    display: none;
  }
  @include media(768px) {
    display: flex;
    margin-bottom: 0;
    width: 125px;
    height: 152px;
  }
  @include media(1200px) {
    width: 152px;
    margin-right: 48px;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.img-count2 {
      width: 100%;
      height: 50%;
    }
    &.img-count4 {
      width: 50%;
      height: 50%;
      flex: 1;
    }
  }
}

.complete-btn {
  margin-top: 36px;

  .btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

.order-view-menu {
  margin-top: 0;
  margin-bottom: 48px;
  padding: 0;

  li {
    list-style: none;
    @include media(640px) {
      display: flex;
    }

    p {
      font-weight: 500;
      @include media(640px) {
        min-width: 194px;
      }
    }
    span {
      color: #8E8D90;
    }
  }
  li+li {
    margin-top: 16px;
  }
}

.btn-group {

  .lose {
    display: inline-block;
    margin-left: 32px;
  }
}

.order_decor-title {
  font-size: 16px;
  line-height: normal;
  color: #8E8D90;
  margin-bottom: 0;
  margin-top: 16px;
  @include media(640px) {
    font-size: 20px;
    line-height: 32px;
  }
  @include media(990px) {
    max-width: 808px;
  }
}

.signup-link {
  margin-top: 36px;
  display: block;
  @include media(640px) {
    margin-top: 64px;
  }

  &.grey {
    color: $color-gray
  }
}

.profile-link {
  line-height: 24px;
  margin-top: 16px;
  font-weight: bold;
  color: $color-blue;
  letter-spacing: 0.01em;
  width: 190px;
  height: 36px;
  background-color: #FFFFFF;
  border: 2px solid $color-blue;
  border-radius: 3px;
  transition: background-color .3s;
  @include media(640px) {
    width: 216px;
    height: 48px;
  }

  &:hover, &:focus {
    color: #fff;
    background-color: $color-blue
  }
}

.tabs {
  padding-top: 32px;
  border-bottom: 1px solid #E5E5E5;
}

.tab-menu {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    font-weight: 500;
    font-size: 15px;
    line-height: normal;
    padding: 0 32px 16px;
    border-bottom: 2px solid transparent;
    transition: border-color .3s;
    @include media(640px) {
      font-size: 18px;
      line-height: 24px;
    }

    &:hover, &:focus {
      border-color: $color-blue;
    }
    &.active {
      border-color: $color-blue;
      a {
        color: $color-black
      }
    }
  }
}

.tabs-content {
  padding-top: 32px;
  @include media(640px) {
    padding-top: 40px;
    max-width: 768px;
  }
}

.pickup-text {
  padding-top: 4px;
}

.delivery-details {
  .radio-wrap {
    margin-bottom: 24px;
  }
  .btn-group {
    margin-top: 24px;
    @include media(640px) {
      margin-top: 32px;
    }
  }
}

.mr_decor {
  margin-bottom: 32px;
}

.order-details-wrap, .order-methods {
  padding-top: 24px;
  @include media(640px) {
    padding-top: 32px;
  }
}

.order-methods {
  padding-bottom: 24px;
  @include media(640px) {
    padding-bottom: 56px;
  }
}

.method-item {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #E5E5E5;
  @include media(640px) {
    padding-bottom: 24px;
    margin-bottom: 32px;
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
  .subtitle {
    margin-bottom: 24px;
  }
  p {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 8px;
    @include media(640px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .details {
    font-size: 12px;
    font-style: normal;
    color: #8E8D90;
    @include media(640px) {
      font-size: inherit;
    }
  }
}