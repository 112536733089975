// Fonts
$font: 'Montserrat';

//Screens
$screen_small: 450px;
$screen_mob: 576px;
$screen_mobile: 640px;
$screen_middle: 768px;
$screen_laptop: 990px;
$screen_desktop: 1200px;
$screen_big: 1408px;
$screen_max: 1621px;

//Colors
$color-red: #E8484C;
$color-yellow: #FF6E40;
$color-yellow-hover: #E6633A;
$color-white: #fff;
$color-shadow: #E5E5E5;
$color-grey: #F8F8F8;
$color-violet: #405DE6;
$color-green: #00C2B7;
$color-green-light: #54E051;
$color-black: #2E2D33;
$color-gray: #9C9C9F;
$color-blue: #4C749F;
$color-blue-h: #021D3A;

//Modal vars
$size-icons: 56px;
