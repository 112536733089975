.page-404-wrap {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  @include media(990px) {
    flex-direction: row;
    align-items: center;
  }
}

.page-404-body {
  width: 100%;
  padding-top: 48px;
  padding-bottom: 64px;
  @include media(640px) {
    width: auto;
    padding-bottom: 0;
  }
  @include media(990px) {
    padding-top: 0;
  }

  h1 {
    font-weight: 600;
    font-size: 64px;
    line-height: 100px;
    color: $color-blue;
    text-align: center;
    margin-bottom: 8px;
    @include media(640px) {
      font-size: 120px;
      line-height: 164px;
    }
    @include media(1200px) {
      font-size: 160px;
      line-height: 190px;
    }
    @include media(1408px) {
      font-size: 184px;
      line-height: 224px;
    }
  }
  p {
    font-size: 16px;
    line-height: 21px;
    color: #8E8D90;
    max-width: 230px;
    margin: 0 auto 48px;
    text-align: center;
    @include media(640px) {
      max-width: 374px;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 64px;
    }
    @include media(990px) {
      text-align: left;
    }
  }
  .profile-link {
    background-color: transparent;
    width: 140px;
    float: right;
    @include media(640px) {
      width: 165px;
      float: none;
      margin: auto;
    }
    @include media(990px) {
      margin: inherit;
    }

    &:hover, &:focus {
      background-color: $color-blue;
    }
  }
}

.page-404-img {
  display: none;
  @include media(990px) {
    display: block;
    padding-left: 42px;
  }
}