.main_header {
  background: url("assets/images/care/care__bg.jpg") center no-repeat;
  margin-top: 32px;
  padding-top: 16px;
  padding-bottom: 48px;
  color: #FFFFFF;
  text-align: center;
  @include media(640px) {
    height: 470px;
    padding-bottom: 0;
  }
  @include media(990px) {
    background: url("assets/images/care/care__bg.jpg") no-repeat;
    height: calc(100vh - 136px);
    max-height: 824px;
  }

  .leading {
    margin: auto;
    padding-top: 32px;
    @include media(640px) {
      padding-top: 0;
    }
  }
  .container {
    flex-direction: column;
  }
  h1 {
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 32px;
    @include media(640px) {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 48px;
    }
    @include media(990px) {
      font-size: 44px;
      line-height: 56px;
    }
    @include media(1200px) {
      font-size: 54px;
      line-height: 68px;
    }
    @include media(1408px) {
      font-size: 64px;
      line-height: 86px;
    }
    @include media(1621px) {
      font-size: 84px;
      line-height: 96px;
    }
  }
  .title {
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
    @include media(640px) {
      font-size: inherit;
    }
  }
  .breadcrumbs {
    color: #fff;

    a {
      color: #fff;

      &:hover, &:focus {
        color: rgba(#fff, .8);
      }
    }
  }
}

.care-content {
  .title {
    margin-bottom: 16px;
  }
  p {
    line-height: 20px;
    margin-bottom: 8px;
  }
}

.care-blocks {
  margin-bottom: 18px;
  @include media(640px) {
    margin-bottom: 24px;
  }
  @include media(990px) {
    margin-bottom: 48px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.row-care {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include media(990px) {
    flex-direction: row;
  }
}

.col-care4 {
  margin-bottom: 18px;
  @include media(640px) {
    margin-bottom: 24px;
  }
  @include media(990px) {
    padding-right: 32px;
    margin-bottom: 0;
    max-width: 60%;
    flex: 0 0 60%;
  }
  @include media(1200px) {
    max-width: 100%;
    flex: none;
    width: calc(100% - 645px);
  }
  @include media(1408px) {
    width: calc(100% - 808px);
  }
}

.col-care6 {
  width: 100%;
  @include media(990px) {
    width: auto;
    max-height: 368px;
    max-width: 40%;
    flex: 0 0 40%;
  }
  @include media(1200px) {
    max-width: 100%;
    flex: none;
    width: 645px;
  }
  @include media(1408px) {
    width: 808px;
  }

  & ~ .col-care4 {
    margin-bottom: 0;
    margin-top: 18px;
    @include media(640px) {
      margin-top: 24px;
    }
    @include media(990px) {
      padding-left: 32px;
      padding-right: 0;
      margin-top: 0;
    }
  }
  img {
    width: 100%;
    @include media(990px) {
      width: auto;
    }
  }
}