/* Hero Slide */
.hero-wrap {
  position: relative;

  .arrow-mount {
    background: rgba(#F8F8F8, .98);
    border-top-left-radius: 90px;
    position: absolute;
    bottom: 3px;
    right: -1px;
    width: 140px;
    height: 40px;
    @include media(640px) {
      width: 175px;
      height: 45px;
    }
    @include media(990px) {
      width: 208px;
      height: 56px;
    }
  }
}

.hero-slide {

  .hero-left {
    right: 70px;
    @include media(640px) {
      right: 88px;
    }
    @include media(990px) {
      right: 102px;
    }
  }
  .hero-right {
    right: 22px;
    @include media(640px) {
      right: 26px;
    }
    @include media(990px) {
      right: 40px;
    }
  }
  .slick-slide {
    img {
      height: 190px;
      width: 100%;
      object-fit: cover;
      @include media(640px) {
        height: 420px;
      }
      @include media(1200px) {
        max-height: 632px;
        height: calc(100vh - 180px);
      }
    }
  }
  .slick-arrow {
    bottom: 0;
    z-index: 5;
    position: absolute;
    cursor: pointer;
    @include media(990px) {
      bottom: 8px;
    }

    &:hover, &:focus {
      svg {
        stroke: #222;
      }
    }
    svg {
      width: 30px;
      height: 30px;
      stroke: $color-blue;
      @include media(640px) {
        width: inherit;
        height: inherit;
      }
    }
  }
}

.hero-item {
  position: relative;
}

.hero-card {
  display: none;
  @include media(640px) {
    display: block;
    position: absolute;
    max-width: 400px;
    padding: 16px 24px;
    bottom: 16px;
    left: 16px;
    background-color: rgba(#FFFFFF, .95);
    border-radius: 4px;

    .title {
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 32px;
      color: #8E8D90
    }
  }
  @include media(990px) {
    max-width: 576px;
    bottom: 24px;
    left: 48px;
  }
}

.hero-link {
  align-items: center;
  float: right;
  font-weight: 500;
  color: $color-blue;

  svg {
    stroke: $color-blue;
    margin-left: 8px;
  }
  &.order-back {
    float: left;
    margin-bottom: 32px;

    svg {
      margin-left: 0;
      margin-right: 8px;
    }
  }
  &:after {
    content: '';
    clear: both;
  }
}

.ones {
  .product-card {
    @include media(990px) {
      height: calc(100% - 32px);
    }
  }
}

.product-card {
  position: relative;
  height: 300px;
  margin-bottom: 32px;
  text-align: center;
  @include media(640px) {
    height: 340px;
  }
  @include media(1200px) {
    height: 388px;
  }

  .title {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0
  }
  .actual-mount {
    position: absolute;
    border: none;
    border-radius: 0 3px 3px 0;
    left: 0;
    width: 100%;
    bottom: 0;
    @include media(640px) {
      width: 304px;
      bottom: 32px;
    }
  }
}

.sort-item {
  position: relative;
  text-align: center;

  h5 {
    font-weight: 500;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 0;
    color: $color-gray
  }
}

.product-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sort-slide {
  padding-top: 24px;
  @include media(640px) {
    padding-top: 32px;
  }

  .product-img {
    outline: 0;
    display: flex;
    height: 320px;
    @include media(640px) {
      height: 360px;
    }
    @include media(990px) {
      height: 400px;
    }

    img {
      width: 100%;
      margin: auto;
      object-fit: cover;
    }
  }
  .slick-track {
    margin: 0;
  }
  .slick-slide {
    outline: 0;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.cats-top {
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
  @include media(640px) {
    flex-direction: row;
    margin-bottom: 32px;
  }

  .hero-link {
    margin-top: 12px;
    justify-content: flex-end;
    @include media(640px) {
      margin-top: 0;
      justify-content: inherit;
    }
  }
}

.delivery-content {
  margin-top: 24px;
}

.delivery-wrap {
  padding-top: 16px;
  color: #8E8D90
}