.catalog_content {
  .subtitle {
    font-weight: 500;
    margin-bottom: 0;
  }
}

.top-slide {
  padding-top: 24px;
  margin-right: -8px;
  margin-left: -8px;
  @include media(640px) {
    padding-top: 32px;
    margin-right: -16px;
    margin-left: -16px;
  }
  @include media(990px) {
    margin-bottom: 24px;
  }

  .slick-track {
    margin: 0;
  }
  .slick-slide {
    outline: 0;
    padding-left: 8px;
    padding-right: 8px;
    @include media(640px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.catalog-top {
  text-align: center;

  &:hover, &:focus {
    img {
      transform: scale(1.2);
    }
    a {
      color: $color-blue
    }
  }
  img {
    height: 200px;
    width: 100%;
    object-fit: cover;
    transition: transform .3s;
    will-change: transform;
    @include media(640px) {
      height: 288px;
    }
  }
  .subtitle {
    font-weight: 500;
    margin-bottom: 8px;
  }
  a {
    display: block;
    margin-bottom: 16px;
    overflow: hidden;
    color: inherit;
  }
  p {
    font-size: 12px;
    color: #8E8D90;
    margin-bottom: 0;
    @include media(640px) {
      font-size: inherit;
    }
  }
}

.catalog-wrap {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  @include media(640px) {
    padding-top: 40px;
  }
  @include media(990px) {
    flex-direction: row;
  }

  &.is_category {
    padding-top: 24px;
    @include media(640px) {
      padding-top: 32px;
    }
  }
}

.sidebar {
  width: 100%;
  margin-bottom: 24px;
  @include media(990px) {
    width: 240px;
    margin-bottom: 0;
  }
}

.sidebar-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  border-bottom: 1px solid #E5E5E5;
  margin-bottom: 0;
  font-size: 12px;
  padding: 8px;
  @include media(640px) {
    font-size: inherit;
    line-height: 24px;
  }
  @include media(990px) {
    display: block;
  }

  .filter-down {
    display: inline-block;
    @include media(990px) {
      display: none;
    }
  }
}

.sidebar-cats {
  padding-top: 24px;
  height: 0;
  visibility: hidden;
  @include media(990px) {
    height: auto;
    visibility: visible;
  }

  .cats-name {
    font-weight: 500;
    cursor: pointer;
    padding: 0 8px 16px;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 16px;

    span {
      margin-left: 10px;
      display: inline-block;
      font-weight: normal;
      color: #8E8D90
    }
    &.active {
      border-bottom: none;
      margin-bottom: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.collapse {
  color: #8E8D90;
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: all .25s;

  &.active {
    opacity: 1;
    height: 100%;
    visibility: visible;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 24px;

    ul  li {
      ul.active {
        opacity: 1;
        height: 100%;
        visibility: visible;
        margin-top: 12px;
        padding-left: 8px;
      }
    }
  }
  ul {
    margin: 0;
    padding-left: 8px;

    li {
      list-style: none;

      &.active {
        span {
          color: #fff;
          background-color: $color-blue;
          padding: 3px 0 4px 8px;
          width: 100%;
        }
      }
      span {
        background-color: transparent;
        border-radius: 2px;
        padding: 3px 0 4px;
        transition: all .2s;
      }
      ul {
        opacity: 0;
        height: 0;
        visibility: hidden;
        padding-left: 0;
        transition: all .25s;
      }
    }
    li+li {
      margin-top: 16px;
    }
  }
}

.catalog-body {
  width: 100%;
  @include media(990px) {
    width: calc(100% - 240px);
    padding-left: 48px;
  }
  @include media(1200px) {
    padding-left: 90px;
  }
  @include media(1408px) {
    padding-left: 120px;
  }
}

.product-list {
  padding-top: 24px;
  @include media(640px) {
    padding-top: 32px;
  }
}

.product-item_img {
  position: relative;
  border: 1px solid #E5E5E5;
  margin-bottom: 16px;
  width: 100%;
  height: 316px;
  cursor: pointer;
  @include media(640px) {
    height: 340px;
  }
  @include media(990px) {
    height: 376px;
  }

  &:hover, &:focus {
    .available-sizes {
      opacity: 1;
    }
  }
  img {
    width: 90%;
    margin: auto;
    object-fit: cover;
  }
  a {
    outline: 0;
  }
  .to_link {
    display: flex;
    height: 100%;
  }
}

.catalog-header {
  margin-bottom: 16px;

  small {
    display: block;
    margin-top: 10px;
    @include media(640px) {
      margin-top: 0;
      margin-left: 16px;
      display: inline-block;
    }
  }
  .subtitle {
    display: inline-block;
  }
}

.shosen-btn {
  font-weight: 600;
  line-height: 16px;
  margin-top: 16px;
}

.add-basket {
  display: block;
  color: $color-blue
}

.product-filter {
  flex-wrap: wrap;
  margin-top: 0;
  margin-right: -16px;
  margin-bottom: -16px;
  padding: 0;

  .dropdown {
    margin-bottom: 16px;
    margin-right: 16px;
    display: inline-block;
  }
  .tooltip-wrap {
    width: 224px;
    left: 50%;
    transform: translateX(-50%);

    .pointer {
      left: 50%;
      margin-left: -12px;
    }
  }
  .tooltip {
    padding: 0;
  }
  .ratings-wrap {
    justify-content: space-between;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.dropdown-header {
  padding: 0 20px 16px 16px;
  overflow-y: scroll;
  margin-top: 16px;
  margin-right: 4px;
  max-height: 316px;

  &::-webkit-scrollbar
  {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb
  {
    border-radius: 1px;
    background-color: #E5E5E5;
    height: 64px;
  }
  small {
    color: inherit;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 16px;
    display: block;
  }
  .menu-reset {
    li {
      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .popular {
    color: $color-gray;
    margin-left: -8px;

    &:before {
      content: '';
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-right: 5px;
    }
  }
}

.dropdown-footer {
  display: flex;
  margin-top: 8px;
  padding: 16px 34px;
  justify-content: center;
  flex-direction: column;
  border-top: 1px solid #E5E5E5;

  .btn {
    width: 100%;
    display: block;
    height: 32px;
  }
}

.drop-btn {
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  display: flex;
  align-items: center;
  color: #8E8D90;
}

.filter-color {
  display: flex;
  justify-content: space-between;

  &:hover, &:focus {
    span {
      color: inherit;
    }
  }
  &.active {
    span {
      color: inherit;
    }
  }
  span {
    color: $color-gray;
  }
}

.color-circle {
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  margin-right: 12px;
}

.price-wrap {
  margin-bottom: 16px;
}

.prices {
  justify-content: space-between;

  span {
    padding: 4px 8px;
    border: 1px solid #E5E5E5;
    border-radius: 2px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: black;
  }
}