/**
  Fonts
 */

@font-face {
  font-family: 'Montserrat';
  src: url("assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal  }

@font-face {
  font-family: 'Montserrat';
  src: url("assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal  }

@font-face {
  font-family: 'Montserrat';
  src: url("assets/fonts/Montserrat-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal  }

@font-face {
  font-family: 'Montserrat';
  src: url("assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal  }