.ds-flex-align {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ds-flex {
  display: flex;
}

.ds-center {
  align-items: center;
}

.ds-justify-center {
  justify-content: center;
}

.ds-direction-column {
  flex-direction: column;
}

.ds-menu-center {
  display: flex;
  justify-content: space-between;
}

.btn {
  line-height: inherit;
  height: 36px;
  padding: 0;
  border: none;
  background-color: transparent;
  @include media(640px) {
    height: 48px;
  }

  &:hover, &:focus {
    outline: 0;
  }
  &.close {
    font-size: 21px;
    height: auto;
  }
}

.btn-blue {
  background-color: $color-blue;
  border: 2px solid transparent;
  letter-spacing: 0.01em;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
  padding: 0 40px;
  border-radius: 3px;

  &:hover, &:focus {
    color: $color-blue;
    background-color: #fff;
    border: 2px solid $color-blue
  }
}

.site-logo-link {
  display: block;
  max-width: 55px;
  @include media(480px) {
    max-width: 70px;
  }
  @include media(640px) {
    max-width: 100%;
  }
}

.input {
  border: none;
  outline: 0;
  padding-left: 16px;
  width: 100%;
  height: 48px;
  color: #9C9C9F;
  @include media(640px) {
    padding-left: 24px;
  }
}

a {
  color: $color-gray;

  &:hover, &:focus {
    color: $color-blue
  }
}

.wrapper {
  min-height: 100vh;
}

.content {
  padding-top: 32px;
  padding-bottom: 48px;
  @include media(640px) {
    padding-bottom: 90px;
  }
  @include media(1200px) {
    min-height: calc(100vh - 418px);
    padding-bottom: 130px;
  }

  &.page-404 {
    padding-top: 0;
    background: url(assets/images/icons/404.svg) bottom left no-repeat;
    background-size: 100%;
    @include media(640px) {
      background: url(assets/images/icons/404.svg) left no-repeat;
    }
    @include media(990px) {
      background: none;
    }
  }
}

.home_content {
  padding-top: 16px;
  padding-bottom: 48px;
  @include media(640px) {
    padding-bottom: 90px;
  }
  @include media(1200px) {
    padding-bottom: 130px;
  }
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: normal;
  color: #2E2D33;
  @include media(990px) {
    font-size: 28px;
    line-height: 36px;
  }
  @include media(1200px) {
    font-size: 32px;
    line-height: 40px;
  }
}

.sale {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 48px;
  height: 32px;
  background-color: $color-red;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.favorite {
  .heart {

  }
  //.col-3 {
  //  flex: 0 0 100%;
  //  max-width: 100%;
  //  @include media(640px) {
  //    flex: 0 0 50%;
  //    max-width: 50%;
  //  }
  //  @include media(990px) {
  //    flex: 0 0 33.333333%;
  //    max-width: 33.333333%;
  //  }
  //}
}

.heart {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  background-color: rgba(#fff, .8);
  border: 1px solid #E5E5E5;
  border-radius: 3px;
  cursor: pointer;
  transition: all .25s ease;

  &.active {
    svg, .path {
      fill: $color-blue
    }
  }
  &:hover, &:focus {
    svg, .path {
      fill: $color-blue;
    }
  }
  svg {
    transition: all .25s;
    fill: transparent;
  }
  .path {
    fill: #8E8D90
  }
}

.old-price {
  text-decoration: line-through;
  margin-right: 24px;
}

.new-price {
  font-weight: bold;
  line-height: 24px;
  color: $color-red;
}

.subtitle {
  font-size: 14px;
  line-height: normal;
  @include media(640px) {
    font-size: 16px;
  }
  @include media(990px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.page-title {
  font-weight: bold;
  font-size: 19px;
  line-height: normal;
  margin-bottom: 16px;
  letter-spacing: 1px;
  @include media(640px) {
    font-size: 28px;
    line-height: 36px;
  }
  @include media(1200px) {
    font-size: 46px;
    line-height: 54px;
  }
  @include media(1408px) {
    font-size: 56px;
    line-height: 64px;
  }

  &.mr_now {
    margin-bottom: 0;
  }
}

.page-subtitle {
  font-weight: 500;
  margin-bottom: 0;
  color: $color-gray;
}

.section-title {
  font-weight: 500;
  margin-bottom: 0;
  font-size: 22px;
  line-height: normal;
  letter-spacing: 0.3px;
  @include media(640px) {
    font-size: 28px;
    line-height: 36px;
  }
  @include media(1200px) {
    font-size: 40px;
    line-height: 48px;
  }

  &.mr_now {
    margin-bottom: 0 !important;
  }
}

.section_pd {
  padding-top: 32px;
  @include media(640px) {
    padding-top: 64px;
  }
}

.input {
  border: 1px solid #E5E5E5;
  border-radius: 3px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
  //margin-bottom: -32px;
}

.mr_row {
  margin-bottom: -32px;
}

.mr_item {
  margin-bottom: 32px;
}

.row-actual {
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -32px;
}

.col-3-product {
  padding-right: 16px;
  padding-left: 16px;
  flex: 0 0 100%;
  max-width: 100%;
  @include media(640px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @include media(1200px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.col-3-actual {
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  float: left;
  @include media(640px) {
    width: 50%;
  }
  @include media(990px) {
    width: 33.333333%;
  }
}

.clearfix {
  clear: both;
}

.col-2 {
  padding-right: 16px;
  padding-left: 16px;
  flex: 0 0 100%;
  max-width: 100%;
  @include media(990px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-3 {
  padding-right: 16px;
  padding-left: 16px;
  flex: 0 0 100%;
  max-width: 100%;
  @include media(640px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @include media(768px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.col-4 {
  padding-right: 16px;
  padding-left: 16px;
  flex: 0 0 100%;
  max-width: 100%;
  @include media(640px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.col-6 {
  padding-right: 16px;
  padding-left: 16px;
  flex: 0 0 100%;
  max-width: 100%;
  @include media(640px) {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

.breadcrumbs {
  margin-bottom: 26px;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: $color-gray;
  @include media(640px) {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 48px;
  }
}

.breadcrumbs-menu {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  @include media(768px) {
    flex-wrap: inherit;
  }

  li {
    list-style: none;
    margin-right: 8px;
    margin-bottom: 6px;
    @include media(640px) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-right: 0;

      &:after {
        content: ''
      }
    }
    &:after {
      content: '/';
      margin-left: 8px;
    }
  }
}

.textarea {
  resize: none;
  height: 128px;
}

.tooltip-wrap {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  top: 100%;
  z-index: 15;
  padding-top: 21px;
  font-weight: normal;
  transition: opacity .2s;

  &.show {
    opacity: 1;
    visibility: visible;
  }
  .pointer {
    width: 32px;
    height: 29px;
    top: 5px;
    position: absolute;
    z-index: 3;
    background: url(assets/images/icons/triangle.svg) no-repeat;
  }
}

.tooltip {
  position: relative;
  background-color: #fff;
  padding: 24px 0;
  border: 1px solid #E5E5E5;
  border-radius: 5px;

  .close, .close-cart {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;

    svg {
      fill: #C8C8C8;
      transition: fill .2s;

      &:hover, &:focus {
        fill: $color-gray;
      }
    }
  }
  .close {
    width: 24px;
    height: 24px;
  }
  .close-cart {
    width: 32px;
    height: 32px;
  }
  .btn-blue {
    height: 32px;
  }
  h5 {
    padding-left: 16px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}

.menu-reset {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

.pagination-wrap {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  @include media(640px) {
    margin-top: 40px;
  }
}

.pagination {
  display: flex;
  margin: 0;
  padding: 0;
  font-weight: bold;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    line-height: 24px;
    width: 32px;
    cursor: pointer;
    height: 32px;
    letter-spacing: 0.01em;
    border: 1px solid #E5E5E5;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
    &:hover, &:focus {
      background-color: $color-blue;

      a {
        color: #fff;
      }
      svg {
        fill: #fff
      }
    }
    &.active {
      background-color: $color-blue;

      a {
        color: #fff;
      }
      svg {
        fill: #fff
      }
    }
    svg {
      fill: #C8C8C8
    }
  }
  a {
    transition: none;
    color: #9C9C9F;
  }
}

.pd_title {
  padding-bottom: 24px;
}

.no-stock {
  color: #CCD7E4;
}

.input-wrap {
  margin-bottom: 16px;
  @include media(640px) {
    font-size: 18px;
    line-height: 24px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
  &.mr_input {
    margin-bottom: 16px;
  }
}

.select {
  appearance: none;
  width: 100%;
  height: 48px;
  padding-left: 24px;
  color: #8E8D90;
  border: 1px solid #E5E5E5;
  border-radius: 3px;
  background: url("assets/images/icons/filter-down.svg") 92% no-repeat;
}

.radio-wrap, .checkbox-wrap {
  display: flex;
  align-items: center;
}

.radio {
  display: none;

  &:checked + label {
    background-color: $color-blue;
  }
  & + label {
    border: 2px solid #E5E5E5;
    background-color: #fff;
    border-radius: 12px;
    height: 24px;
    cursor: pointer;
    transition: background-color .2s;
    width: 24px;
    margin-right: 16px;
  }
}

.checkbox {
  display: none;

  &:checked + label {
    background-color: $color-blue;
    border: 2px solid $color-blue;

    &:before {
      content: '';
      opacity: 1;
    }
  }
  & + label {
    position: relative;
    border-radius: 2px;
    border: 2px solid #E5E5E5;
    background-color: #fff;
    height: 24px;
    cursor: pointer;
    transition: background-color .2s;
    width: 24px;
    margin-right: 16px;

    &:before {
      content: '';
      background: url("assets/images/icons/checkbox-bg.svg") center no-repeat;
      display: block;
      height: inherit;
      z-index: 0;
      top: -2px;
      width: 100%;
      position: absolute;
      transition: opacity .2s;
      opacity: 0;
    }
  }
}

.label {
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
  font-size: 11px;
  line-height: normal;
  letter-spacing: 0.4px;
  @include media(640px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.available-sizes {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px 30px 8px;
  background-color: rgba(#fff, .9);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  opacity: 0;
  transition: opacity .3s;

  p {
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 16px;
    color: #101011;
  }
  .sizes {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -8px;

    span {
      margin-left: 16px;
      margin-bottom: 8px;

      &:first-of-type {
        margin-left: 0;
      }
    }
    .now {
      text-decoration: line-through;
      color: #9C9C9F;
    }
  }
}

.btn-delete {
  color: $color-red;
  margin-right: 36px;

  .icons {
    margin-right: 8px;
  }
  svg {
    stroke: $color-red;
  }
}

.btn-update {
  color: $color-green;

  .icons {
    margin-right: 8px;
  }
  svg {
    fill: $color-green
  }
}

.password {
  position: relative;

  .icons {
    position: absolute;
    right: 16px;
    top: 14px;
    cursor: pointer;

    &:hover, &:focus {
      svg {
        fill: $color-blue-h
      }
    }
    svg {
      fill: $color-blue;
    }
  }
}

.dropdown {
  position: relative;

  &.open {
    .dropdown-body {
      opacity: 1;
      visibility: visible;
    }
  }
}

.dropdown-body {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  z-index: 5;
  top: 100%;
  right: 12px;
  padding: 15px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s;

  .lang-link {
    & + .lang-link {
      margin-top: 12px;
    }
  }
}