/**
    Grid container
 */

.container {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 18px;
  width: 100%;
  @media (min-width: 640px) { padding: 0 1.5rem; }
  @media (min-width: $screen_desktop) { max-width: 73rem; }
  @media (min-width: $screen_big) { max-width: 80rem; }
  @media (min-width: $screen_max) { max-width: 88rem }
}