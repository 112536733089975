.modal_overlay {
  will-change: visibility, opacity;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.35);
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.modal-popup {
  display: block;
  width: 100%;
  padding: 36px 32px 20px;
  height: auto;
  margin: 0 auto;
  background: white;
  border-radius: 5px;
  z-index: 999;
  overflow: hidden;
  transform: translateY(-100%);
  box-shadow: 0 0 20px rgba(56, 56, 56, 0.2);
  position: relative;
  top: 50%;
  transition: all 900ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.popup--active {
  transform: translateY(-50%);
}

.modal-popup .close {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 24px;
  cursor: pointer;
  transition: .5s;

  svg {
    fill: #8E8D90;

    &:hover, &:focus {
      fill: #C8C8C8
    }
  }
}

#login_modal {
  max-width: 512px;
}